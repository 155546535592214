import React from 'react'

export default () => (
  <section id="open-source">
    <h2>Open Source</h2>
    <ul>
      <li>
        <strong>
          <a href="https://github.com/michaelwnelson/labor-notifier">
            labor-notifier
          </a>
        </strong>
        <br />
        This is a simple Twilio application that will automatically text
        persons who have elected to be notified when your child is born
        preventing you from manually notifying friends and family, giving
        you time back to focus on your newborn.
      </li>
      <li>
        <strong>
          <a href="https://github.com/michaelwnelson/reddit-modqueue-discord">
            reddit-modqueue-discord
          </a>
        </strong>
        <br />
        This is a simple bot that will authenticate to a given reddit
        account, pull reports from a given subreddit, and post the new
        reports to your Discord.
      </li>
      <li>
        <strong>
          <a href="https://github.com/michaelwnelson/pytracker">
            pytracker
          </a>
        </strong>
        <br />
        This is a project I forked and updated to automate Placemark
        Investment's Pivotal Tracker processes. It's a simple Python API
        that wraps the Pivotal Tracker REST APIs.
      </li>
      <li>
        <strong>
          <a href="https://github.com/michaelwnelson/fcdallas-css">
            fcdallas-css
          </a>
        </strong>
        <br />
        I created this project to provide a custom stylesheet for the
        FC Dallas subreddit:{' '}
        <strong>
          <a href="http://reddit.com/r/fcdallas">
            http://reddit.com/r/fcdallas
          </a>
        </strong>
      </li>
      <li>
        <strong>
          <a href="https://github.com/michaelwnelson/mls-standings-scraper">
            mls-standings-scraper
          </a>
        </strong>
        <br />
        I created this project, similar to fcdallas-css above, to
        provide a Markdown table of the MLS standings for the FC Dallas
        subreddit sidebar.
      </li>
      <li>
        <strong>
          <a href="https://github.com/myforce/angularjs-dropdown-multiselect">
            angularjs-dropdown-multiselect
          </a>
        </strong>
        <br />
        Contributor.
      </li>
      <li>
        <strong>
          <a href="https://github.com/reactjs/react-tabs">react-tabs</a>
        </strong>
        <br />
        Contributor.
      </li>
    </ul>
  </section>
);
